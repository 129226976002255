<template>
  <div class="grey lighten-3">
    <div class="simple-header pa-8 text-center white--text">
      <h1>بزرگترین فروشگاه فایل های لایه باز موهو</h1>
    </div>
    <v-row class="pa-2 ma-0">
      <v-col cols="12" md="3">
        <div class="rounded-xl pa-4 white mx-3 moholand-shadow">
          <v-row no-gutters>
            <h3>نرم افزار:</h3>
            <v-col
              cols="12"
              class="mt-2"
              v-for="(apps, i) in applications"
              :key="'sorts' + i"
            >
              <v-btn
                block
                light
                class="rounded-lg"
                :color="
                  selectedApplication == apps.id
                    ? 'success lighten-2 black--text'
                    : 'grey lighten-2'
                "
                large
                elevation="0"
                @click="onSelectApplication(apps)"
              >
                {{ apps.name }} ({{ apps.format + '.' }})
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <moments-offer />
      </v-col>
      <v-col cols="12" md="9" class="pa-4">
        <v-container fluid class="pt-0">
          <div class="d-flex rounded-lg white align-center moholand-shadow">
            <v-text-field
              v-model="name"
              flat
              solo
              label="دنبال چی میگردی؟"
              hide-details
              class="rounded-r-lg"
              @keyup.enter="onSubmit"
            />
            <v-btn
              elevation="0"
              fab
              class="rounded-l-lg rounded-0"
              color="orange darken-1"
              dark
              @click="onSubmit"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>

          <div class="d-flex align-center mt-2 flex-wrap">
            <span>
              <v-icon color="black">mdi-sort-ascending</v-icon>
              مرتب سازی:
            </span>
            <v-btn
              v-for="(sort, i) in sortBy"
              :key="'sorts' + i"
              text
              class="rounded-lg my-auto"
              :color="sortBySelected == sort.slug ? 'orange' : 'grey darken-3'"
              elevation="0"
              @click="onSelectSort(sort)"
            >
              {{ sort.name }}
            </v-btn>
            <v-spacer />
            <small v-if="pagesLength">
              مشاهده صفحه
              {{ current_page }}
              از
              {{ pagesLength }}
            </small>
          </div>

          <v-row class="mt-2" v-if="loading">
            <v-col
              lg="3"
              md="4"
              sm="6"
              cols="12"
              v-for="(product, i) in 8"
              :key="'skeleton' + i"
            >
              <v-skeleton-loader
                type="card, card-heading"
                class="moholand-shadow"
              />
            </v-col>
          </v-row>

          <v-row class="mt-2" v-else>
            <v-col lg="3" md="4" sm="6" cols="12">
              <ProductBox
                v-for="(product, i) in firstCol"
                :key="'products' + i"
                :product="product"
                :name="product.name"
                :url="product.url"
                :img="product.media ? product.media.url : null"
                :price="
                  product.offer_price ? product.offer_price : product.price
                "
                :old-price="product.offer_price && product.price"
                class="mb-3"
                dynamicHeight
              />
            </v-col>
            <v-col lg="3" md="4" sm="6" cols="0">
              <ProductBox
                v-for="(product, i) in secondCol"
                :key="'products' + i"
                :product="product"
                :name="product.name"
                :url="product.url"
                :img="product.media ? product.media.url : null"
                :price="
                  product.offer_price ? product.offer_price : product.price
                "
                :old-price="product.offer_price && product.price"
                class="mb-3"
                dynamicHeight
              />
            </v-col>
            <v-col lg="3" md="4" sm="0" cols="0">
              <ProductBox
                v-for="(product, i) in thirdCol"
                :key="'products' + i"
                :product="product"
                :name="product.name"
                :url="product.url"
                :img="product.media ? product.media.url : null"
                :price="
                  product.offer_price ? product.offer_price : product.price
                "
                :old-price="product.offer_price && product.price"
                class="mb-3"
                dynamicHeight
              />
            </v-col>
            <v-col lg="3" md="0" sm="0" cols="0">
              <ProductBox
                v-for="(product, i) in fourthCol"
                :key="'products' + i"
                :product="product"
                :name="product.name"
                :url="product.url"
                :img="product.media ? product.media.url : null"
                :price="
                  product.offer_price ? product.offer_price : product.price
                "
                :old-price="product.offer_price && product.price"
                class="mb-3"
                dynamicHeight
              />
            </v-col>
          </v-row>
          <my-paginate
            v-model="page"
            :length="pagesLength"
            @onChange="onPageChanged"
          />
          <h4 class="text-center mt-15 grey--text" v-if="!total && !loading">
            هیچ فایلی مطابق با جستجوی شما یافت نشد!
          </h4>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MyPaginate from '../../components/MyPaginate.vue';
import CartButton from '../../components/Pages/CartButton.vue';
import MomentsOffer from '../../components/Pages/MomentsOffer.vue';
import ProductBox from '../../components/Pages/ProductBox.vue';
import MyAxios from '@/constants/MyAxios';

export default {
  components: { MyPaginate, ProductBox, CartButton, MomentsOffer },
  data() {
    return {
      name: this.$route.query.name || '',
      sortBy: [
        {
          name: 'جدیدترین ها',
          slug: 'newest',
          icon: 'mdi-star',
        },
        {
          name: 'پرفروش ترین',
          slug: 'bestselling',
          icon: 'mdi-star',
        },
        {
          name: 'محبوب ترین ها',
          slug: 'popular',
          icon: 'mdi-star',
        },
        {
          name: 'ارزان ترین ها',
          slug: 'cheapest',
          icon: 'mdi-star',
        },
        {
          name: 'تخفیفات ویژه',
          slug: 'offers',
          icon: 'mdi-star',
        },
      ],
      sortBySelected: 'newest',
      applications: [],
      selectedApplication: this.$route.query.software || null,
      selectedCategoty: this.$route.query.category_id || null,
      items: [],
      page: +this.$route.query?.page || 1,
      pagesLength: null,
      path: null,
      total: null,
      current_page: null,
      loading: true,
      categories: [],
    };
  },
  computed: {
    currentSize() {
      if (this.$vuetify.breakpoint.lgAndUp) return 4;
      else if (this.$vuetify.breakpoint.mdAndUp) return 3;
      else if (this.$vuetify.breakpoint.smAndUp) return 2;
      return 1;
    },
    firstCol() {
      return this.getCoefficientsArray(this.items, this.currentSize);
    },
    secondCol() {
      return this.getCoefficientsArray(this.items, this.currentSize, 1);
    },
    thirdCol() {
      return this.getCoefficientsArray(this.items, this.currentSize, 2);
    },
    fourthCol() {
      return this.getCoefficientsArray(this.items, this.currentSize, 3);
    },
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler() {
        if (this.$route.query?.category_id)
          this.selectedCategoty = this.$route.query?.category_id;
        if (this.$route.query?.sort)
          this.sortBySelected = this.$route.query?.sort;
        if (this.$route.query?.software)
          this.selectedApplication = this.$route.query?.software;
        this.getData();
      },
    },
  },
  methods: {
    getCoefficientsArray(arr, coefficient, offset = 0) {
      return arr.reduce(
        (result, current, i) =>
          i % coefficient == offset ? [...result, current] : result,
        []
      );
    },
    getApplications() {
      MyAxios.get('/software/index', {
        params: {
          noPaginate: true,
        },
      })
        .then(response => {
          this.applications = response.data;
        })
        .catch(error => {
          console.log('laws', error.response);
        });
    },
    getData() {
      this.loading = true;
      window.scrollTo({ top: 0 });
      MyAxios.get('/products/search', {
        params: {
          conditions: {
            name: this.name || undefined,
            category: this.$route.query.category_id || undefined,
            software_id: this.$route.query.software || undefined,
          },
          sort: this.$route.query.sort || undefined,
          page: this.$route.query?.page || 1,
          tag: this.$route.query?.tag || undefined,
        },
      })
        .then(response => {
          this.items = response.data.data;
          this.pagesLength = response.data.last_page;
          this.path = response.data.path;
          this.total = response.data.total;
          this.current_page = response.data.current_page;
          console.log('response.data', response.data);

          const arr = Array.from({ length: 10 }, (_, i) => i);

          console.log('1 column', this.getCoefficientsArray(arr, 3));
          console.log('2 column', this.getCoefficientsArray(arr, 3, 1));
          console.log('3 column', this.getCoefficientsArray(arr, 3, 2));
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
        });
    },
    onSubmit() {
      this.$router.push({
        name: 'Products',
        query: {
          ...this.$route.query,
          name: this.name,
        },
      });
      this.getData();
    },
    onSelectApplication(item) {
      if (item.id == this.selectedApplication) {
        this.selectedApplication = undefined;
        this.$router.push({
          name: 'Products',
          query: {
            name: this.name || undefined,
            software: undefined,
            category_id: this.selectedCategoty || undefined,
            sort: this.sortBySelected || undefined,
          },
        });
      } else {
        this.selectedApplication = item.id;
        this.$router.push({
          name: 'Products',
          query: {
            name: this.name || undefined,
            software: item.id || undefined,
            category_id: this.selectedCategoty || undefined,
            sort: this.sortBySelected || undefined,
          },
        });
      }
      this.page = 1;
      this.getData();
    },
    onSelectCategory(item) {
      if (item.id == this.selectedCategoty) {
        this.selectedCategoty = undefined;
        this.$router.push({
          name: 'Products',
          query: {
            name: this.name || undefined,
            category_id: undefined,
            software: this.selectedApplication || undefined,
            sort: this.sortBySelected || undefined,
          },
        });
      } else {
        this.selectedCategoty = item.id;
        this.$router.push({
          name: 'Products',
          query: {
            name: this.name || undefined,
            category_id: item.id || undefined,
            software: this.selectedApplication || undefined,
            sort: this.sortBySelected || undefined,
          },
        });
      }
      this.page = 1;
      this.getData();
    },
    onSelectSort(item) {
      if (item.slug == this.sortBySelected) {
        this.sortBySelected = undefined;
        this.$router.push({
          name: 'Products',
          query: {
            name: this.name || undefined,
            software: this.selectedApplication || undefined,
            category_id: this.selectedCategoty || undefined,
            sort: undefined,
          },
        });
      } else {
        this.sortBySelected = item.slug;
        this.$router.push({
          name: 'Products',
          query: {
            name: this.name || undefined,
            software: this.selectedApplication || undefined,
            category_id: this.selectedCategoty || undefined,
            sort: item.slug || undefined,
          },
        });
      }
      this.page = 1;
      this.getData();
    },
    onPageChanged(page) {
      this.$router.push({
        name: 'Products',
        query: {
          ...this.$route.query,
          page,
        },
      });
    },
  },
  created() {
    this.getApplications();
  },
};
</script>

<style scoped>
.simple-header {
  width: 100%;
  background: url('../../assets/header.jpg');
}
</style>
